:root {
  --color-primary: #000000;
  --color-secondary: #ffffff;
  --color-tercerira: #575757;
  --color-quarta: #3D3D3D
}


* {
  font-family: 'Montserrat', sans-serif;
}

.ant-layout{
  background: var(--color-primary);
}

.express-class .ant-form-item{
  margin: 0px !important;
}


.borda-white{
  border-color: #fff !important;
}

.ant-btn-dashed span{
  color: white;
}

.no-bg-btn {
  background: transparent;
}

.label-tabelaproposta {
  font-size: 9px;
}

.bg-secondary {
  background: transparent;
  border: 1px solid #888888;
  color: #888888;
}

.ant-input[disabled],
.ant-select-disabled .ant-select-selector,
.ant-picker.ant-picker-disabled {
  background-color: #fff !important;
}

.form-de-envio-docs .ant-form-item{
  margin: 0px !important;
}


.tabela-analytics a:hover {
  color: #c0c0c0;
}

.ant-checkbox-disabled+span {
  color: #fff !important;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #000;
  border-color: #000;
}


.ant-input-number-group .ant-input-number-group-addon{
  color: #fff !important;
}


.calculo-chave-na-mao {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
}

.swiper-slide img {
  height: 500px;
  max-height: 500px;
  height: 100%;
}


.tab-custom {
  cursor: pointer;
}

.custom-active {
  text-decoration: underline;
  text-underline-offset: 5px;

}

.calculo-chave-na-mao .ant-tabs-ink-bar {
  background: #000000 !important;
  margin: 0px;
  height: 3px !important;
}

.calculo-chave-na-mao .ant-tabs-nav::before {
  border-bottom: 3px solid #d8d8d8 !important;
}

.calculo-chave-na-mao .ant-tabs-tab-btn {
  color: var(--color-secondary) !important;
}

.media-mercado-nacional .ant-spin {
  color: var(--color-primary) !important;
}


.tabela-propostas .ant-spin {
  color: var(--color-primary) !important;
}

.ant-spin {
  color: var(--color-secondary);
}



.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--color-secondary);
}


.ant-tabs-tab {
  color: var(--color-tercerira);
  font-size: 17px !important;
}

.ant-tabs .ant-tabs-ink-bar {
  background: var(--color-secondary);
}

.ant-tabs-top>.ant-tabs-nav::before {
  border-bottom: 3px solid var(--color-tercerira);
}

.ant-input {
  border-radius: 0.25rem;
}

.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: 0px;
}

.ant-tabs .ant-tabs-tab:hover {
  color: var(--color-secondary);
}

.ant-list-split .ant-list-item {
  border-color: var(--color-tercerira);
  padding: 8px 0px;
}


.tag {
  background: var(--color-primary);
}






/* .swiper-button-next,
.swiper-button-prev {
    transform: scale(.8) !important;
    border-radius: 3px !important;
    background: #161616 !important;
    color: #fff !important;
    height: 40px !important;
    width: 40px !important;
}


.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 22px !important;
}

.swiper-button-next,
.swiper-button-prev {
    top: 50% !important;
}

.swiper-button-prev,
.swiper-button-next {
    left: 10px;
    right: auto;
}

.swiper-container {
  width: 100%;
  overflow: hidden;
} */



.mensagem-de-erro {
  border: 1px solid red;
  background: #fff !important;
  color: red;
  font-size: 15px;
}

.mensagem-de-sucesso {
  border: 1px solid #44A575;
  background: #fff !important;
  color: #44A575;
  font-size: 15px;
}

.mensagem-de-sucesso svg {
  color: #44A575;
  font-size: 30px;
  width: 100%;
  max-width: 24px;
}

.mensagem-de-erro svg {
  color: red;
  font-size: 30px;
  width: 100%;
  max-width: 24px;
}

/* styles.css */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}