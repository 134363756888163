:root {
    --dark-primary: #151515;
    --dark-secondary: #ffffff;
    --dark-tercerira: #000000;
}

.site-layout{
    background: var(--dark-tercerira);
    border-radius: 5px;
}

.card-sidebar {
    background: var(--dark-primary) !important;
    color: var(--dark-secondary);
}

.card-main{
    background: var(--dark-tercerira);
    color: var(--dark-secondary);
    padding: 0px 15px;
}

.ant-layout-header, .ant-layout .ant-layout-sider{
    background: var(--dark-tercerira);
}

.sidebar .ant-menu-dark, .ant-menu-sub.ant-menu-inline{
    background: var(--dark-primary) !important;
}

.ant-menu-item-selected{
    background: var(--dark-tercerira) !important;
}

.ant-btn-primary:hover{
    background: var(--dark-primary) !important;
    color: var(--dark-secondary);
}
