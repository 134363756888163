/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;




body {
  /* background: linear-gradient(45deg, #1f1f1f, #090909); */
  background-color: #000000;
  font-family: 'Montserrat', sans-serif;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background-color: #1c1c1c;
}

.card-white .ant-btn-primary:disabled{
  color: #555555 !important;
}

.ant-form-item .ant-form-item-label >label{
  color: #fff !important;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: #1c1c1c;
}

.card-car{
  background-color: #000000;
  padding: 15px;
  border-radius: 5px;
}

.detalhes-de-log-email .ant-slider .ant-slider-track {
  background-color: #c5c5c5 !important;
  height: 4px !important;
}

.ant-btn-primary:disabled {
  color: #b3b3b340 !important;
}

.ant-picker-ok button {
  background: #000;
}

.ant-divider-horizontal {
  margin: 15px 0px;
}

.passo-a-passo .ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #000;
}

.detalhes-de-log-email .anticon {
  color: #000;
}

.ant-menu-dark.ant-menu-submenu>.ant-menu,
.ant-menu-dark>.ant-menu.ant-menu-submenu>.ant-menu {
  background-color: #1c1c1c;
}

.ferramentas-de-apoio a:hover {
  color: #fff !important;
  text-decoration: underline !important;
}

.ant-table-wrapper td.ant-table-column-sort {
  background: none !important;
}

.header-func .img-user-logo {
  max-width: 90% !important;
  border-radius: 50% !important
}

.ant-segmented-item-selected .ant-segmented-item-label {
  font-weight: 800;
}


.ant-modal-footer .ant-btn.ant-btn-primary {
  background-color: #000000;
}

.borda-icon {
  border-radius: 50%;
  border: 1px solid #fff;
  padding: 5px;
  width: 35.4px;
  height: 35.4px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}


.historico-pesquisas .ant-image-preview-img {
  border-radius: 0px !important;
}

.borderless-select .ant-select-selector {
  border: 0px !important
}

.card-hover:hover {
  box-shadow: 0px 0px 14px 0px rgb(255 92 53);
}

.card-hover {
  transition: box-shadow 0.3s ease-in-out;
}

.ant-select-selector {
  background-color: white !important;
}

.ant-select-arrow svg,
.ant-select-selection-item {
  color: #000 !important;
}

* {
  border-color: #575757 !important;
}

.ant-table-row:nth-child(odd) {
  background-color: black !important;
}

.ant-table-row:nth-child(even) {
  background-color: #1c1c1c !important;
}


.tabela-propostas .ant-table-thead .column-hubspot {
  background-color: #ff5c35 !important;
  color: #fff !important;
  text-align: center !important;
}

.ant-table-thead .column-hubspot {
  background-color: #ff5c35 !important;
  color: #fff !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: 0px !important;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
  color: #000 !important;
}

.ant-table-wrapper .ant-table-cell-fix-left,
.ant-table-wrapper .ant-table-cell-fix-right {
  background-color: #1c1c1c;
}


.ant-table-wrapper .ant-table-thead>tr>th,
.ant-table-wrapper .ant-table-thead>tr>td {
  background-color: #1c1c1c;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #fff;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #767676;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #767676 !important;
  border-color: #767676 !important;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #fff;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after,
.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
  background-color: #767676;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #fff;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #7BBE9D !important;
  border-color: #7BBE9D !important;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
  color: #000;
}

.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #fff;
}

.ant-switch {
  background: rgb(74 74 74 / 25%) !important;
}

.ant-switch.ant-switch-checked {
  background: #4096ff !important;
}

.recharts-legend-item-text {
  color: #fff !important;
}

.recharts-layer.recharts-cartesian-axis-tick {
  font-size: 12px !important;
}

.hubspot-content .proposta-aceite {
  background: #0d9f57 !important;
}

.hubspot-content .proposta-aceite a:hover {
  color: #000 !important;
}


.hubspot-content .proposta-aceite .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  background: #0d9f57 !important;
}

/* .hubspot-content .proposta-aceite .ant-table-cell.ant-table-cell-row-hover{
  background: #0d9f57 !important;
} */

/* .ant-table-tbody .proposta-aceite .ant-table-tbody>tr.ant-table-row:hover>td{
  background: #0d9f57 !important;
} */
/* 

 */

.proposta-aceite .ant-table-cell-row-hover {
  background: #0d9f57 !important;
}

.ant-table-cell-row-hover {
  background: #000000 !important;
}

.ant-table-wrapper .ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: #1c1c1c;
}


.alinhar-centro {
  height: calc(100vh - 45px);
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-input-prefix .anticon {
  vertical-align: 0px !important;
  color: #000;
}

.ant-upload-list-item.ant-upload-list-item-undefined span {
  color: #fff !important;
}

.accept-login {
  color: #0d9f57 !important;
}

.accept-login .icon-accept {
  color: #0d9f57 !important;
}

.anticon {
  vertical-align: 0px !important;
  color: #fff;
}

/* .hubspot-modal .ant-modal-content{
  background-color:#1C1C1C;
} */


.media-mercado-nacional .anticon {
  color: #000 !important;
}

.tabela-propostas .anticon {
  color: #000 !important;
}

.outros-foms .anticon {
  color: #000 !important;
}


.ant-table-wrapper .anticon {
  color: #fff !important;
}


.ant-pagination .ant-pagination-item a {
  color: #fff;
}

.ant-table-cell {
  /* background:#1c1c1c !important; */
  color: #fff !important;
  padding: 3px 10px !important;
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #fff !important;
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #575757 !important;
}

.hubspot-btn {
  background-color: #ff5c35;
  color: #fff !important;
}

.alertas-hubspot .mensagem-de-erro {
  border: 1px solid red !important;
  background: #fff !important;
  color: red;
  font-size: 15px;
  padding: 7px;
}

.alertas-hubspot .mensagem-de-sucesso {
  border: 1px solid #44A575 !important;
  background: #fff !important;
  color: #44A575;
  font-size: 15px;
  padding: 7px;
}

.hubspot-btn:hover {
  background-color: #e04826 !important;
}

/* .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-tbody >tr >td{
  border-color: #575757 !important;
} */

.ant-pagination-item-active a {
  color: #000 !important;
}


.ant-pagination-item-active {
  border-color: #000 !important;
}

.ant-pagination .ant-pagination-next button,
.ant-pagination .ant-pagination-prev button {
  color: #fff;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: #f5f5f5 !important;
  color: #000;
}


.ant-image-mask {
  display: none !important;
}

.ant-image .ant-image-img {
  cursor: pointer;
}

.frase-style {
  max-width: 70%;
  width: 100%;
  text-align: center;
}

.modelo_es .ant-select-item-option-content {
  white-space: inherit;
}

.modelo_es .ant-select-dropdown {
  width: 375px;
}

.login-section {
  max-width: 400px;
  margin: auto;
  width: 100%;
}

/* .icon-question {
  cursor: pointer;
  background: #f3f3f3;
  padding: 2px;
  border-radius: 5px;
  font-size: 20px;
  color: #737373;
} */


.bg-black .icon-question {
  cursor: pointer;
  background: #000;
  padding: 1px;
  border-radius: 5px;
  font-size: 17px;
  color: #b2b2b2;
}

.credito .icon-question {
  cursor: pointer;
  background: #000;
  padding: 1px;
  border-radius: 5px;
  font-size: 17px;
  color: #b2b2b2;
}


.icon-question {
  cursor: pointer;
  background: #fff;
  padding: 2px;
  border-radius: 5px;
  font-size: 17px;
  color: #727272;
}


.custom-warning-modal .ant-btn-primary {
  color: #fff;
  background-color: #000 !important;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  outline: none !important;
}

.custom-warning-modal .ant-modal-confirm-btns {
  text-align: start;
  margin-inline-start: 33px;
}


.iconc {
  font-size: 20px;
}

.ant-tabs,
.ant-list-items {
  font-family: 'Montserrat', sans-serif;
}

.form-gerar-proposta .ant-form-item {
  margin: 0px !important;
}

.card {
  background-color: #151515;
  padding: 15px;
  border-radius: 5px;
}

.hover-style .ant-btn-link:not(:disabled):not(.ant-btn-disabled):hover{
  color: #000 !important;
}

.card-email-falhado {
  background-color: #d52b1e;
  padding: 15px;
  border-radius: 5px;
  color: #fff;
}

.card-email-falhado-zero {
  background-color: #75ad13;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
}




.card-sec {
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}


/* .card-cinza {
  background-color: #d1d1d1;
  padding: 15px;
  border-radius: 5px;
} */

.card-cinza:hover {
  border-color: #fff !important;
}

.card-erro {
  background-color: #ab0000;
  padding: 15px;
  border-radius: 5px;
}

.card-expirada {
  background-color: #d6d600;
  padding: 15px;
  border-radius: 5px;
}


.ant-switch-inner {
  border: 1px solid;
}

.card-hubspot {
  background-color: #FF5C35;
  padding: 15px;
  border-radius: 5px;
}

.card-hubspot hr {
  border-color: #fff !important;
}

.card-hubspot .titulo-form {
  border-bottom: 3px solid #fff !important;
  margin-bottom: 25px;
}



.card-white {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

.card-green {
  background-color: #0d9f57;
  padding: 10px;
  border-radius: 5px;
}

.card-em-aberto {
  background-color: #fff6;
  padding: 10px;
  border-radius: 5px;
}

.menu-item {
  background-color: #000 !important;
  background: var(--dark-primary);
  border-radius: 8px;
  padding: 15px;
}

.tab .ant-collapse-header {
  color: #fff !important;
}


.ant-btn.ant-btn-lg {
  background-color: #3D3D3D;
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: #1c1c1c !important;
  border: 1px solid #fff;
  color: #fff;
}

.btn-green:hover {
  background-color: transparent !important;
  border: 1px solid #2ae26e !important;
  color: #fff;
}

.btn-blue:hover {
  background-color: transparent !important;
  border: 1px solid #649fff !important;
  color: #fff;
}


.ant-modal-content .anticon {
  color: #000;
}


.link-partilha-sec .icon-question {
  font-size: 28px;
}


.main-content {
  width: 100% !important;
  padding: 1rem;
}

.hubspot-menu .ant-menu-submenu-title {
  color: #ff5c35 !important;
}

.hubspot-menu-main .ant-menu-light .ant-menu-item-selected {
  background-color: #ff5c35 !important;
}

.hubspot-menu-main .ant-menu-submenu-title {
  color: #ff5c35 !important;
}

.hubspot-menu-li {
  background-color: #fff !important;
  color: #000 !important;
  padding-left: 24px !important;
}


.hubspot-content a:hover {
  color: #ff5c35 !important;
}


@media screen and (max-width: 1023px) {
  .main-content {
    width: 100% !important;
  }

  .swiper-slide img {
    height: 300px !important;
    max-height: 300px;
    height: 100%;
  }

  .frase-style {
    max-width: 100%;
  }

  /* .main-content.sidebar-escondida {
    width: calc(100% - 85px) !important;
  }

  .sidebar-header.sidebar-visivel {
    width: 250px;
    transition: width 0.5s;
  }

  .sidebar-header.sidebar-escondida {
    width: 65px;
    transition: width 0.5s;
  }

  .sidebar-header.sidebar-escondida .menu-item {
      justify-content: center;
      padding: 0px;
      margin: 0px;
  } */

}

.form-de-envio-docs {
  display: grid;
  gap: 1rem;
  grid-gap: 1rem;
}

.form-de-envio-docs .ant-form-item {
  margin: 0px !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #000 !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f0f0f0;
}

.input-error {
  border: 1px solid red;

}



.border-card {
  border: 1px solid #575757;
  padding: 15px;
  border-radius: 5px;
}

.border-card-red {
  border: 1px solid #ffffff;
  padding: 15px;
  border-radius: 5px;
}

.popup-associar .titulo-form {
  border-bottom: 3px solid #575757;
  margin-bottom: 0px;
}

.hubspot-card .titulo-form-hubspot {
  /*margin-top: 15px;*/
  border-bottom: 3px solid #575757;
}




.titulo-form {
  /*margin-top: 15px;*/
  border-bottom: 3px solid #575757;
  margin-bottom: 15px;
}



.titulo-form p {
  padding-bottom: 7px;
  font-size: 18px;
  font-weight: 600;
}



::-webkit-scrollbar-track {
  border-radius: 8px;
  box-shadow: inset 0 0 2px #454545;
}

::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}



.swiper-button-next,
.swiper-button-prev {
  transform: scale(.8) !important;
  border-radius: 3px !important;
  background: #161616 !important;
  color: #fff !important;
  height: 40px !important;
  width: 40px !important;
}

.swiper-button-prev:after {
  font-size: 17px !important;
}

.swiper-button-next:after {
  font-size: 17px !important;
}

.custom-collapse-header {
  color: white;
  /* Cor branca para o texto do cabeçalho */
}

.ant-collapse-expand-icon {
  color: white;
  /* Cor branca para o texto do cabeçalho */
}


.ant-slider .ant-slider-track {
  background-color: rgb(255 255 255) !important;
  height: 4px !important;
}

.ant-slider .ant-slider-rail {
  background-color: #575757 !important;
  height: 4px !important;
}

/* .ant-form-item{
  margin: 0px !important;
} */

.ant-slider .ant-slider-handle::after {
  width: 14px !important;
  height: 14px !important;
  background-color: #ffffff !important;
  box-shadow: 0 0 0 3px #1C1C1C !important;
  inset-inline-start: 0px;
  inset-block-start: -1px;
}

.swiper-button-next,
.swiper-button-prev {
  transform: scale(.8) !important;
  border-radius: 3px !important;
  background: #161616 !important;
  color: #fff !important;
  height: 40px !important;
  width: 40px !important;
}

.swiper-button-prev:after {
  font-size: 17px !important;
}

.swiper-button-next:after {
  font-size: 17px !important;
}

.custom-collapse-header, .ant-collapse-header-text{
  color: white;
  /* Cor branca para o texto do cabeçalho */
}

.ant-collapse-expand-icon {
  color: white;
  /* Cor branca para o texto do cabeçalho */
}


.icon-question {
  height: 16px;
}

.ant-switch-inner {
  border: 1px solid;
}

.ant-input[disabled] {
  color: #999 !important;
}

.border-card {
  border: 1px solid #575757;
  padding: 15px;
  border-radius: 5px;
  transition: border-color 0.3s ease;
  /* Adiciona uma transição suave ao border-color */
}

.border-card:hover {
  border-color: #fff !important;
}


.bg-cinza {
  background: #d9d9d9 !important;
}

.ant-input-group-addon {
  color: #fff !important;
}

.border-card-tipo {
  border: 1px solid #575757;
  border-radius: 5px;
  padding: 15px;
}

.credito-main-block .ant-form-item {
  margin: 0px !important;
}

.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 3px;
  color: #000;
}

.recharts-legend {
  list-style: none;
  padding: 0;
  margin: 0;
}

.recharts-legend li {
  margin-bottom: 5px;
}